import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PhoneProvider } from "./context/PhoneContext";

import Test1 from "./pages/test1";
import Test2 from "./pages/test2";
import Test3 from "./pages/test3";
import VerifyCode from "./pages/verifyCode";
import Checkout from "./components/checkout";
import FanSmartTicket from "./pages/FanSmartTicket";
import SuperFanRegistration from "./pages/SuperFanRegistration";
import PaymentSuccess from "./pages/FanSmartTicket/payment-status-pages/success";
import PaymentFailed from "./pages/FanSmartTicket/payment-status-pages/failed";
import RedeemerPage from "./pages/Redeemer";

function App() {
  return (
    <BrowserRouter>
      <PhoneProvider>
        <Routes>
          <Route path="/" element={<Test1 />} />
          <Route path="/verify" element={<VerifyCode />} />
          <Route path="/schedule" element={<Test2 />} />
          <Route path="/hidden-nfct" element={<Test3 />} />
          <Route path="/fan-pass" element={<FanSmartTicket />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/success" element={<PaymentSuccess />} />
          <Route path="/failed" element={<PaymentFailed />} />
          <Route path="/super-fan" element={<SuperFanRegistration />} />
          <Route path="/1corporatecourtlevel9" element={<RedeemerPage />} />
        </Routes>
      </PhoneProvider>
    </BrowserRouter>
    // </CssVarsProvider>
  );
}

export default App;
