import React, { useState } from "react";
import Cookies from "js-cookie";

import img_bg from "../../img/bg-schedule.png";

import { useNavigate } from "react-router-dom";

export default function RedeemerPage() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const session = JSON.parse(Cookies.get("session_token") || "{}");

  const navigate = useNavigate();
  return (
    <div
      className="min-h-screen"
      style={{
        backgroundImage: `url(${img_bg})`,
      }}
    >
      <div className=" w-screen flex shadow-md px-1 pt-2">
        {/* <img src={logo} alt="logo" className="h-7" /> */}
      </div>
      <div className="">
        <p className="text-md text-white/75 text-center font-medium my-10 mx-5">
          Redeem{" "}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-yellow-700 font-bold">
            FAN SmartTicket
          </span>
          .
          <br />
        </p>
      </div>

      <div className="mx-20 mt-1">
        <div className="mt-4">
          <div className="flex items-center justify-between"></div>
          <input
            type="phone"
            placeholder="phone"
            className="block border w-full px-4 py-2 mt-2 rounded-lg bg-gray-500/40 text-gray-300 border-yellow-600"
            onChange={(e) => setData({ ...data, phone: e.target.value })}
          />
        </div>
        <div className="mt-6">
          <button
            className="w-full px-6 py-2.5 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-gradient-to-r from-yellow-500 to-yellow-700 rounded-lg hover:bg-gray-700 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-50"
            onClick={async () => {
              //   console.log(session.token);
              if (!data.phone) {
                alert("Please enter your phone");
                return;
              } else {
                setLoading(true);
                try {
                  const response = await fetch(
                    "https://australia-southeast1-smart-ticket-394606.cloudfunctions.net/smart-ticket-prod-redeem-offer",
                    {
                      method: "POST",
                      headers: {
                        Authorization: `Bearer ${session.token}`,
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({ phoneNumber: data.phone }),
                    }
                  );

                  if (!response.ok) {
                    throw new Error("Server response was not ok.");
                  }

                  const responseData = await response.json();
                  console.log("respond", responseData);

                  if (responseData.error) {
                    alert(responseData.error);
                  } else if (responseData.message) {
                    alert(responseData.message);
                  }

                  setLoading(false);
                } catch (error) {
                  setLoading(false);
                  console.error("Error:", error);
                  alert(error.message);
                }
              }
            }}
          >
            Redeem
          </button>
        </div>
      </div>
    </div>
  );
}
