import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import { PhoneContext } from "../context/PhoneContext";
import { BiSolidLeftArrow } from "react-icons/bi";
import { HiMiniPaperAirplane } from "react-icons/hi2";

import img_bg from "../img/pa-bg.png";
import logo from "../img/PA_Mark_White-2 1.png";

import LoadingIcons from "react-loading-icons";

export default function VerifyCode() {
  const navigate = useNavigate();

  const { phone } = useContext(PhoneContext);

  console.log(phone);

  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const checkCode = () => {
    setLoading(true);
    setError(""); // Clear any previous error messages
    fetch(
      "https://australia-southeast1-smart-ticket-394606.cloudfunctions.net/smart-ticket-prod-check-token",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phoneNumber: phone, verifyCode: code }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);

        const cookieData = {
          ...data,
          phoneNumber: phone,
        };

        const expiryDate = new Date();
        // Set cookie expiry date to 80 hours in milliseconds
        expiryDate.setTime(expiryDate.getTime() + 80 * 60 * 60 * 1000);
        Cookies.set("session_token", JSON.stringify(cookieData), {
          expires: expiryDate,
        });

        navigate("/schedule");
      })
      .catch((error) => {
        console.error("Error:", error);
        setError("Invalid code. Please try again.");
        setLoading(false);
      });
  };

  return (
    <div>
      <img src={img_bg} alt="bg" className="w-screen h-screen opacity-70" />

      {loading ? (
        <div className=" top-[50vh] left-0 w-screen absolute z-20">
          <LoadingIcons.Bars
            fill="#FC0A57"
            className="text-md text-xs w-20 h-14 m-auto"
          />
        </div>
      ) : (
        <div className="absolute top-0 left-0 w-screen h-screen">
          <button
            type="button"
            className=" text-white mt-5 flex flex-row font-bold text-lg"
            onClick={() => {
              window.open("/", "_self");
            }}
          >
            {/* <img src={arrowBack} alt="back icon" className="w-6 h-6 mx-2" /> */}
            <BiSolidLeftArrow className="w-6 h-6 ml-2 mr-1 my-auto" />
            <span>Go back</span>
          </button>
          <div className="flex mt-36 text-center">
            <div className="m-auto">
              <img src={logo} alt="logo" className="h-20 mx-auto my-auto" />
              <p className="text-center text-2xl font-bold text-white">
                Huntington Beach 2023
              </p>
              <p className="text-center text-sm text-white mt-7">
                Input code sent to your number
              </p>
              <div className="bg-white rounded-2xl p-2 mt-4 w-64 flex">
                <input
                  placeholder="Enter code"
                  className="h-10 mx-auto my-auto"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  type="number"
                />
                <button
                  type="submit"
                  className="focus:outline-none focus:shadow-outline rounded-xl bg-[#F26C8F]"
                  onClick={checkCode}
                >
                  {/* <img
                    src={logo35}
                    alt="logo"
                    className="w-10 h-6 mx-auto my-auto"
                  /> */}

                  <HiMiniPaperAirplane
                    color="white"
                    className="w-10 h-6 mx-auto my-auto"
                  />
                </button>
              </div>
              {error && (
                <p className="text-center text-sm text-white mt-3">{error}</p>
              )}
            </div>
          </div>
          <div className="my-auto mx-auto p-2 w-screen"></div>
        </div>
      )}
    </div>
  );
}
