import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";

import {Tab, Tabs, TabList, TabPanel} from "react-tabs";

import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";

import CardOverflow from "@mui/joy/CardOverflow";
import Divider from "@mui/joy/Divider";

import Stack from "@mui/joy/Stack";

import logo from "../img/PA_Mark_White-2 1.png";
import LoadingIcons from "react-loading-icons";
import {FaMapLocationDot} from "react-icons/fa6";

import BottomLabel from "../img/BottomLabel2.png";

import img_bg from "../img/bg-schedule.png";

export default function Test2() {
  const session = JSON.parse(Cookies.get("session_token") || "{}");
  const navigate = useNavigate();

  console.log(session);

  const [selectedDay, setSelectedDay] = useState("Friday");
  // const [airshowData, setAirshowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // New loading state
  const [searchTerm, setSearchTerm] = useState("");

  const [airshowData, setAirshowData] = useState({}); // Change to an object

  useEffect(() => {
    // Function to fetch data from the API
    async function fetchAirshowData() {
      setIsLoading(true); // Start loading
      try {
        const response = await fetch(
          "https://australia-southeast1-smart-ticket-394606.cloudfunctions.net/smart-ticket-prod-get-schedule",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${session.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setAirshowData(data); // Store the entire response
        setIsLoading(false); // Stop loading after successful data fetch
      } catch (error) {
        console.error("Error fetching airshow data:", error);
        setIsLoading(false); // Stop loading even when error occurs
      }
    }

    fetchAirshowData(); // Fetch data when the component mounts
  }, []); // Remove selectedDay from the dependency array

  const days = ["Friday", "Saturday", "Sunday"];

  const sessionToken = Cookies.get("session_token");
  useEffect(() => {
    if (!sessionToken) {
      navigate("/");
    }
  }, [sessionToken, navigate]);

  if (!sessionToken) {
    return null;
  }

  return (
    <div
      className="bg-[#010373] min-h-screen"
      style={{
        backgroundImage: `url(${img_bg})`,
      }}
    >
      <div className="fixed">
        <div className=" w-screen flex justify-between shadow-md px-1 pt-2">
          <img src={logo} alt="logo" className="h-7" />
          <FaMapLocationDot
            className="w-7 h-7 text-white mr-4"
            onClick={() => window.open("Event-Map-v6.pdf", "_blank")}
          />
        </div>
        <div className=" text-white">
          <div className="flex w-screen">
            <input
              placeholder="Search"
              className=" transition-all duration-300 w-screen mx-7 text-white bg-white/20 p-2 rounded-2xl my-3"
              onChange={(event) => setSearchTerm(event.target.value)}
            />
          </div>

          <div className="w-fit mx-auto rounded-t-2xl">
            <Tabs
              selectedIndex={days.indexOf(selectedDay)}
              onSelect={(index) => setSelectedDay(days[index])}
              // className="rounded-t-2xl transition-all"
            >
              <TabList className="mx-auto w-fit flex mt-2 bg-white/20 rounded-t-2xl transition-none">
                <Tab
                  selectedClassName="bg-[#EE1E58] rounded-tl-2xl"
                  className="py-1.5 px-7 transition-colors duration-300"
                >
                  Friday
                </Tab>
                <Tab
                  selectedClassName="bg-[#EE1E58] "
                  className="py-1.5 px-7 transition-colors duration-300"
                >
                  Saturday
                </Tab>
                <Tab
                  selectedClassName="bg-[#EE1E58] rounded-tr-2xl"
                  className="py-1.5 px-7 transition-colors duration-300"
                >
                  Sunday
                </Tab>
              </TabList>
            </Tabs>
          </div>
        </div>
      </div>
      {/* <hr className="mt-2" /> */}
      <div className="fixed top-36 inset-x-0 mx-auto">
        {isLoading ? (
          <div className="mx-auto flex h-[50vh]">
            <LoadingIcons.Bars
              fill="#FC0A57"
              className="text-md text-xs w-20 h-14 m-auto"
            />
          </div>
        ) : (
          <div className="overflow-auto max-h-[100vh] transition-all duration-300 pb-48">
            <Stack>
              {airshowData[selectedDay.toLowerCase()] // Use the selected day to get the relevant data
                ?.filter(
                  (item) =>
                    (item.performer &&
                      item.performer
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())) || // Performer name contains the search term
                    (item.description &&
                      item.description
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())) // or Description contains the search term
                )
                .map((item) => (
                  <OverflowCard
                    img={item.photourl} // Update to match the new API response structure
                    title={item.performer} // Update to match the new API response structure
                    time={item.showtime} // Update to match the new API response structure
                    presenter={item.description} // Update to match the new API response structure
                  />
                ))}
            </Stack>
          </div>
        )}
      </div>
      {/* <img
        src={BottomLabel}
        alt="logo"
        className="w-screen mx-auto my-auto fixed bottom-0 z-20"
        onClick={() => window.open("/screen2", "_self")}
      /> */}
      {/* <div className="flex w-screen bg-[#171717]">
      </div> */}
    </div>
  );
}

function OverflowCard({img, title, time, presenter}) {
  return (
    <div className="m-3 p-2 mx-auto">
      <Card
        variant="outlined"
        sx={{
          width: 320,
          // bgcolor: "#FC0A57",
          // bgcolor: "#f8f8f84f",
          border: "none",
          boxShadow: "none",
          // opacity: 0.5,
        }}
      >
        <CardOverflow>
          <AspectRatio ratio="2">
            <img src={img} loading="lazy" alt="" />
          </AspectRatio>
        </CardOverflow>
        <CardContent>
          <CardContent orientation="horizontal">
            <p className="flex-grow font-semibold text-lg my-auto text-[#EE1E58] capitalize">
              {title}
            </p>
            <Divider orientation="vertical" />
            <p className="font-base text-lg my-auto ">{time ? time : "time"}</p>
          </CardContent>
          <Typography level="body2" sx={{mt: 0.5}}>
            {presenter}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}
