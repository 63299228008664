import React from "react";
import img_standard from "../img/standard Large.png";
import img_vip from "../img/VIP Large.png";

import FWB from "../img/FWB.png";

import img_bg from "../img/bg-schedule.png";

import img_features from "../img/Text-schedule.png";
import logo from "../img/PA_Mark_White-2 1.png";
import { BiSolidLeftArrow } from "react-icons/bi";

export default function Test3() {
  return (
    <div
      className="min-h-screen"
      style={{
        backgroundImage: `url(${img_bg})`,
      }}
    >
      <div className=" w-screen flex justify-between shadow-md px-1 pt-2">
        <button
          type="button"
          className=" text-white flex flex-row text-sm "
          onClick={() => {
            window.open("/schedule", "_self");
          }}
        >
          {/* <img src={arrowBack} alt="back icon" className="w-6 h-6 mx-2" /> */}
          <BiSolidLeftArrow className="w-5 h-5 ml-2 mr-1 my-auto" />
          {/* <span className="">Back to schedule</span> */}
        </button>
        <img src={logo} alt="logo" className="h-7" />
      </div>

      <div className="">
        <p className="text-md text-white/75 text-center font-semibold my-5 mx-5">
          <span className="font-black text-white">Secure your spot</span> for
          next year with a VIPNFT, select from:
        </p>
        <img
          src={img_standard}
          alt="logo"
          className="w-screen mx-auto px-2 my-5"
          onClick={() => window.open("/fan-pass", "_self")}
        />

        <img
          src={img_vip}
          alt="logo"
          // className="mx-auto"
          className="w-screen mx-auto px-2  my-5"
          onClick={() => window.open("/super-fan", "_self")}
        />
      </div>

      {/* <img
        src={img_features}
        alt="logo"
        className="w-screen mx-auto px-2 pt-5"
      /> */}

      <p className="text-sm text-white/75 text-center font-medium mx-5 mb-2 mt-10">
        <span className="font-semibold text-white">VIPNFT</span> Baseline
        Benefits:
      </p>

      <p className="text-sm text-white/75 text-left font-medium mb-2 list-disc list-inside w-fit mx-auto">
        <li>Early Bird access to next year's Tickets</li>
        <li>First access to PA 2023 VIPNFT Series</li>
        <li>
          10% discount to next year's event (General <br /> Admission Ticket
          only)
        </li>
        <li>Support and Connect with Performers</li>
        <li>50% Discount on VIPNFT 360 Booth</li>
      </p>

      <p className="text-center text-sm text-white/80 mt-10 px-10 font-base underline pb-5">
        <a
          href="/PAGC-2023-Term-and-Conditions.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms & Conditions
        </a>
      </p>
    </div>
  );
}
