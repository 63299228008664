import React, { useState } from "react";
import img_hero from ".././img/fan-pass-hero.png";
import Cookies from "js-cookie";

import img_bg from "../../../img/bg-schedule.png";

import { useNavigate } from "react-router-dom";

export default function PaymentFailed() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const session = JSON.parse(Cookies.get("session_token") || "{}");

  const navigate = useNavigate();
  return (
    <div
      className="min-h-screen"
      style={{
        backgroundImage: `url(${img_bg})`,
      }}
    >
      <div className=" w-screen flex shadow-md px-1 pt-2">
        {/* <img src={logo} alt="logo" className="h-7" /> */}
      </div>
      <div className="">
        <img
          src={img_hero}
          alt="logo"
          className="w-screen mx-auto px-10 my-5"
        />{" "}
        <p className="text-md text-white/75 text-center font-medium my-10 mx-5">
          Payment Failed!
          <br />
          <br />
          Please re try your payment.
        </p>
      </div>

      <div className="mx-20 mt-1">
        <div className="mt-6">
          <button
            className="w-full px-6 py-2.5 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#2A4DD0] rounded-lg hover:bg-gray-700 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-50"
            onClick={() => {
              window.open("/fan-pass", "_self");
            }}
          >
            Back to SmartTicket
          </button>
        </div>
      </div>
    </div>
  );
}
