import React, { useState, useContext } from "react";
import { PhoneContext } from "../../context/PhoneContext";
import img_hero from "./img/fan-pass-hero.png";
import Cookies from "js-cookie";

import img_bg from "../../img/bg-schedule.png";

import img_benefirs from "./img/fan-pass-benefits.png";

import { useNavigate } from "react-router-dom";

import { BiSolidLeftArrow } from "react-icons/bi";

export default function FanSmartTicket() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { phone, setPhone } = useContext(PhoneContext);

  const session = JSON.parse(Cookies.get("session_token") || "{}");

  const navigate = useNavigate();
  return (
    <div
      className="min-h-screen"
      style={{
        backgroundImage: `url(${img_bg})`,
      }}
    >
      <div className=" w-screen flex shadow-md px-1">
        <button
          type="button"
          className=" text-white mt-5 flex flex-row text-sm"
          onClick={() => {
            window.open("/schedule", "_self");
          }}
        >
          {/* <img src={arrowBack} alt="back icon" className="w-6 h-6 mx-2" /> */}
          <BiSolidLeftArrow className="w-3 h-3 ml-2 mr-1 my-auto" />
          <span>Back to schedule</span>
        </button>
      </div>
      <div className="">
        <img
          src={img_hero}
          alt="logo"
          className="w-screen mx-auto px-10 my-5"
        />{" "}
        <p className="text-md text-white/75 text-center font-medium my-10 mx-5">
          Upgrade to a{" "}
          <span className="font-semibold text-white">SmartTicket</span>.
          <br />
          and receive the following benefits:
        </p>
      </div>
      <p className="text-sm text-white/75 text-left font-medium mb-2 list-disc list-inside w-fit mx-auto">
        <li>Early Bird access to next year's Tickets</li>
        <li>First access to PA 2023 VIPNFT Series</li>
        <li>
          10% discount to next year's event (General <br /> Admission Ticket
          only)
        </li>
        <li>Support and Connect with Performers</li>
        <li>50% Discount on VIPNFT 360 Booth</li>
      </p>
      {/* <p className="text-sm text-white/75 text-center font-medium mx-5 mb-2">
        <span className="font-semibold text-white">FAN SmartTicket</span>{" "}
        Benefits
      </p> */}
      {/* <div>
        <img
          src={img_benefirs}
          alt="logo"
          className="w-screen mx-auto px-7 py-2"
        />
      </div> */}

      <p className="text-md text-white text-center font-bold mx-5 mb-10 mt-10 uppercase ">
        Only 1,000 available
      </p>
      <p className="text-4xl text-center font-bold mb-10 mt-10 text-white/75">
        $29
      </p>
      <div className="mx-10 mt-1">
        <div className="mt-4">
          <div className="flex items-center justify-between"></div>
          <input
            type="email"
            placeholder="Email"
            className="block border w-full px-4 py-2 mt-2 rounded-lg bg-gray-500/40 text-gray-300 border-white"
            onChange={(e) => setData({ ...data, email: e.target.value })}
          />
        </div>
        <div className="mt-6">
          <button
            className="w-full px-6 py-2.5 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#2A4DD0] rounded-lg hover:bg-gray-700 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-50"
            onClick={() => {
              if (!data.email) {
                alert("Please enter your email");
                return;
              } else {
                try {
                  const cookieData = {
                    ...session,
                    email: data.email,
                  };

                  Cookies.set("session_token", JSON.stringify(cookieData), {
                    expires: 7,
                  });

                  navigate("/checkout");
                } catch (error) {
                  alert(error.message);
                }
              }
            }}
          >
            Upgrade Now
          </button>
        </div>
      </div>
    </div>
  );
}
