import React, { useState, useContext } from "react";
import img_hero from ".././img/fan-pass-hero.png";
import Cookies from "js-cookie";

import img_bg from "../../../img/bg-schedule.png";

import img_benefirs from ".././img/fan-pass-benefits.png";

import { useNavigate } from "react-router-dom";

export default function PaymentSuccess() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const session = JSON.parse(Cookies.get("session_token") || "{}");

  const navigate = useNavigate();
  return (
    <div
      className="min-h-screen"
      style={{
        backgroundImage: `url(${img_bg})`,
      }}
    >
      <div className=" w-screen flex shadow-md px-1 pt-2">
        {/* <img src={logo} alt="logo" className="h-7" /> */}
      </div>
      <div className="">
        <img
          src={img_hero}
          alt="logo"
          className="w-screen mx-auto px-10 my-5"
        />{" "}
        <p className="text-md text-white/75 text-center font-medium my-10 mx-5">
          Congrats! You have successfully purchased the{" "}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-indigo-400 font-bold">
            Pacific Airshow Fan SmartTicket
          </span>{" "}
          <br />
          <br />
          More instruction to manage your NFT will be sent to
          {session.token ? (
            <span className="font-semibold uppercase text-white">
              {" "}
              {session.email}
            </span>
          ) : (
            <span className="font-semibold uppercase text-white">
              {" "}
              register your email to claim your NFT.
            </span>
          )}
          <br />
          <br />
          Keep an eye on your email after the airshow
        </p>
      </div>

      <div className="mx-20 mt-1">
        <div className="mt-6">
          <button
            className="w-full px-6 py-2.5 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform  bg-gradient-to-r from-blue-600 to-indigo-400 rounded-lg hover:bg-gray-700 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-50"
            onClick={() => {
              window.open(
                "https://goerli.etherscan.io/address/0x049A311D13834888EE13c68Ec070Fa8BA5B25302#nfttransfers",
                "_self"
              );
            }}
          >
            See your NFT in Etherscan
          </button>
        </div>
      </div>
    </div>
  );
}
