import { loadStripe } from "@stripe/stripe-js";

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      "pk_live_51NcjveH8vj49aZK1lhLo9vFMXmRvcR9nt5mN7CN1PmhhsW6pflKJEwXzNDoyJ3GLyiiv4X4nwC86NW7bF2JpvQwF00ekXkk4cD"
    );
  }
  return stripePromise;
};

export default getStripe;
