import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PhoneContext } from "../context/PhoneContext";
import Cookies from "js-cookie";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import img_bg from "../img/pa-bg.png";
import logo from "../img/PA_Mark_White-2 1.png";

import { HiMiniPaperAirplane } from "react-icons/hi2";

import LoadingIcons from "react-loading-icons";

export default function Test1() {
  const navigate = useNavigate();

  const { phone, setPhone } = useContext(PhoneContext);
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  console.log(phone);

  const sendToken = () => {
    setLoading(true);
    setError(""); // Clear any previous error messages
    fetch(
      "https://australia-southeast1-smart-ticket-394606.cloudfunctions.net/smart-ticket-prod-send-token",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phoneNumber: phone }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);
        setLoading(false);

        alert("Verification code sent!");

        navigate("/verify");
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
        setError("An error occurred. Please try again.");
      });
  };

  const sessionToken = Cookies.get("session_token");
  useEffect(() => {
    if (sessionToken) {
      console.log("sessionToken", sessionToken);
      navigate("/schedule");
    }
  }, [sessionToken, navigate]);

  if (sessionToken) {
    return null;
  }

  return (
    <div>
      <img src={img_bg} alt="bg" className="w-screen h-screen" />

      <div className="absolute top-0 left-0 w-screen h-screen">
        {/* <div className="">
        </div> */}
        {loading ? (
          <div className="absolute top-0 left-0 w-screen h-screen bg-black bg-opacity-50 flex flex-col justify-center items-center">
            <LoadingIcons.Bars className="text-white h-20" />
            <p className="text-white mt-10">Loading...</p>
          </div>
        ) : (
          <div className="flex mt-36">
            <div className="m-auto">
              <img src={logo} alt="logo" className="h-20 mx-auto my-auto" />
              <p className="text-center text-2xl font-bold text-white">
                Huntington Beach 2023
              </p>
              <div className="bg-white rounded-2xl p-2 mt-7 w-64 flex">
                <PhoneInput
                  inputStyle={{
                    width: "12.5rem",
                    borderStyle: "none",
                    outline: "none",
                    fontSize: "15px",
                  }}
                  buttonStyle={{
                    borderStyle: "none",
                    outline: "none",
                    backgroundColor: "transparent",
                  }}
                  dropdownStyle={{ width: "15rem" }}
                  searchStyle={{ width: "12rem" }}
                  country={"us"}
                  enableSearch={true}
                  value={phone}
                  onChange={(phone) => setPhone(`+${phone}`)}
                />
                <button
                  type="submit"
                  className="focus:outline-none focus:shadow-outline rounded-lg bg-[#EE1D5A]"
                  onClick={sendToken}
                >
                  {/* <img src={logo35} alt="logo" className="w-10 h-6 mx-auto my-auto" /> */}
                  <HiMiniPaperAirplane
                    color="white"
                    className="w-10 h-6 mx-auto my-auto"
                  />
                </button>
              </div>
              {error && (
                <p className="text-center text-sm text-white mt-3">{error}</p>
              )}
              <p className="text-center text-md text-white mt-3 font-semibold">
                Unlock Schedule and Map
              </p>
            </div>
          </div>
        )}
        <div className="my-auto mx-auto p-2 w-screen absolute bottom-5">
          <p className="text-center text-sm text-white mt-3 px-10 font-light">
            By entering your mobile you agree to <br />
            sign up for notifications and alerts.
          </p>
          <p className="text-center text-sm text-white/80 mt-3 px-10 font-semibold">
            <a
              href="https://pacificairshowaus.com/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            {" | "}
            <a
              href="https://www.pacificairshow.com/terms-of-service-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
