import React, { useEffect } from "react";
import getStripe from "./lib/getStripe";
import Cookies from "js-cookie";

export default function Checkout({}) {
  const session = JSON.parse(Cookies.get("session_token") || "{}");
  async function handleCheckout() {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: "price_1NfcvhH8vj49aZK1MHK9wDfs",
          quantity: 1,
        },
      ],
      mode: "payment",
      successUrl: `https://pacificairshow.smartticket.app/success`,
      cancelUrl: `https://pacificairshow.smartticket.app/cancel`,
      customerEmail: session.email,
      clientReferenceId: session.phoneNumber,
    });
    console.warn(error.message);
  }

  useEffect(() => {
    console.log("stripe session: ", session);
    handleCheckout();
  }, []);
}
