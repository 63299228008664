import React, { useState, useContext } from "react";
import img_hero from "./img/fan-pass-hero.png";
import Cookies from "js-cookie";
import { BiSolidLeftArrow } from "react-icons/bi";

import img_bg from "../../img/bg-schedule.png";

import img_benefirs from "./img/fan-pass-benefits.png";

import { useNavigate } from "react-router-dom";

export default function SuperFanRegistration() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const session = JSON.parse(Cookies.get("session_token") || "{}");

  const navigate = useNavigate();
  return (
    <div
      className="min-h-screen"
      style={{
        backgroundImage: `url(${img_bg})`,
      }}
    >
      <div className=" w-screen flex shadow-md px-1">
        <button
          type="button"
          className=" text-white mt-5 flex flex-row text-sm"
          onClick={() => {
            window.open("/schedule", "_self");
          }}
        >
          {/* <img src={arrowBack} alt="back icon" className="w-6 h-6 mx-2" /> */}
          <BiSolidLeftArrow className="w-3 h-3 ml-2 mr-1 my-auto" />
          <span>Back to schedule</span>
        </button>
      </div>

      <div className="">
        <img
          src={img_hero}
          alt="logo"
          className="w-screen mx-auto px-10 my-5"
        />{" "}
        <p className="text-md text-white/75 text-center font-medium my-10 mx-5">
          Pacific Airshow Huntington Beach{" "}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-yellow-700 font-bold">
            VIPNFT Series
          </span>{" "}
          <br />
          <span className="font-semibold uppercase text-white">
            {" "}
            Coming Soon
          </span>
          .
          <br />
          <br />
          Register your interest
        </p>
      </div>

      <div className="mx-20 mt-1">
        <div className="mt-4">
          <div className="flex items-center justify-between"></div>
          <input
            type="email"
            placeholder="Email"
            className="block border w-full px-4 py-2 mt-2 rounded-lg bg-gray-500/40 text-gray-300 border-yellow-600"
            onChange={(e) => setData({ ...data, email: e.target.value })}
          />
        </div>
        <div className="mt-6">
          <button
            className="w-full px-6 py-2.5 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-gradient-to-r from-yellow-500 to-yellow-700 rounded-lg hover:bg-gray-700 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-50"
            onClick={async () => {
              console.log(session.token);
              if (!data.email) {
                alert("Please enter your email");
                return;
              } else {
                setLoading(true);
                try {
                  const response = await fetch(
                    "https://australia-southeast1-smart-ticket-394606.cloudfunctions.net/smart-ticket-prod-set-email-nt",
                    {
                      method: "POST",
                      headers: {
                        Authorization: `Bearer ${session.token}`,
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({ email: data.email }),
                    }
                  );
                  //   const responseData = await response.json();
                  setLoading(false);
                  alert("Your email has been registered");
                  // handle your data here
                } catch (error) {
                  setLoading(false);
                  console.error("Error:", error);
                }
              }
            }}
          >
            Register
          </button>
        </div>
      </div>
      <p className="text-center text-sm text-white/80 mt-10 px-10 font-base underline pb-5">
        <a href="https://vipnft.fan" target="_blank" rel="noopener noreferrer">
          VIPNFT
        </a>
      </p>
    </div>
  );
}
